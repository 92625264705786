import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { SENIORITY_SELECT_OPTIONS, JOB_ROLE_SELECT_OPTIONS } from 'constants/staffing';
import { TIME_ZONES } from 'constants/timeZones';

// library components
import { Formik, Form, FieldArray, Field } from 'formik';
import { Button, Grid, InputLabel, IconButton, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import ClearIcon from '@material-ui/icons/Clear';
import { useConfirm } from 'material-ui-confirm';

//validation library
import * as Yup from 'yup';

// cr components
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';

import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
import TextArea from 'components/CustomFormComponents/TextArea';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';

//actions & selectors
import { updateContractorUser } from 'redux/actions/staffingActions';
import { selectSignedInContractor } from 'redux/selectors/staffing/getContractor';
import {
  selectContractor,
  selectFiltersSelectOptions,
} from 'redux/selectors/staffing/getContractor';

import { useFirestore } from 'react-redux-firebase';
import AutoSuggestTextInput from 'components/CustomFormComponents/AutoSuggestTextInput';
import { map } from 'lodash';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 6,
  },
  removeButton: {
    marginLeft: 5,
    borderRadius: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  addButton: {
    width: 80,
    marginTop: 5,
    background: '#DCE5EF',
    borderRadius: 5,
    color: '#093B75',
    fontSize: 14,
    '&:hover': {
      background: '#DCE5EF',
      opacity: 0.9,
    },
  },
  addRoleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 25,
    background: '#DCE5EF',
    borderRadius: 5,
    color: '#093B75',
    fontSize: 14,
    '&:hover': {
      background: '#DCE5EF',
      opacity: 0.9,
    },
  },
  removeRoleButton: {
    fontSize: 12,
  },
  cancelButton: {
    width: 105,
    height: 40,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  helperText: {
    fontSize: 12,
    display: 'inline',
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const FieldText = React.memo(({ name }) => (
  <Field
    name={`${name}`}
    component={({ field }) => (
      <TextInput name={name} type="text" className="flex-grow-1" required />
    )}
  />
));

const MyInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const db = useFirestore();
  const confirm = useConfirm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);

  const getContractor = selectSignedInContractor();
  const contractor = useSelector(state => getContractor(state));

  const getFilters = selectFiltersSelectOptions();
  const { cities, states, countries } = useSelector(state => getFilters(state));

  useEffect(() => {
    if (contractor) {
      setIsLoading(false);
    }
  }, [contractor]);

  useEffect(() => {
    if (cities.length > 0 && countries.length > 0 && states.length > 0) {
      setIsFiltersLoading(false);
    }
  }, [cities, states, countries]);

  if (isLoading || isFiltersLoading) {
    return <div>Loading...</div>;
  }

  // TODO: display rate - hourlyRate: contractor ? contractor.hourlyRate : '',
  // TODO: display vcEmail
  // TODO: Fix styles.... lines don't matchup
  // TODO: Zip code?
  const formInitialValues = {
    email: contractor?.email ?? '',
    vcEmail: contractor?.vcEmail ?? 'N/A',
    displayName: contractor?.displayName ?? '',
    otherEmails: contractor?.otherEmails ?? [],
    phones: contractor?.phones ?? [],
    languages: contractor?.languages ?? [],
    address: contractor?.address ?? '',
    city: contractor?.city ?? '',
    state: contractor?.state ?? '',
    postalCode: contractor?.postalCode ?? '',
    country: contractor?.country ?? '',
    timeZone: contractor?.timeZone ?? '',
    emergencyContactName: contractor?.emergencyContactName ?? '',
    emergencyContactPhone: contractor?.emergencyContactPhone ?? '',
    emergencyContactEmail: contractor?.emergencyContactEmail ?? '',
  };

  const formSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email address').required('Required'),
    otherEmails: Yup.array().of(Yup.string().email('Must be a valid email address')),
    phones: Yup.array().of(Yup.string()),
    languages: Yup.array().of(Yup.string()),
    displayName: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    timeZone: Yup.string(),
    emergencyContactName: Yup.string().required('Required'),
    emergencyContactPhone: Yup.string().required('Required'),
    emergencyContactEmail: Yup.string().email('Must be a valid email address').required('Required'),
  });

  const handleFormSubmit = (values, { setErrors }) => {
    if (formInitialValues.email !== values.email) {
      confirm({
        //title: 'Confirm Email Change',
        description:
          'Are you sure you want to change your email? This will affect your login credentials.',
      }).then(() => {
        // If confirmed, proceed with form submission
        submitForm(values, setErrors);
      });
    } else {
      // No email change, proceed with form submission
      submitForm(values, setErrors);
    }
  };

  const submitForm = async (values, setErrors) => {
    setIsSubmitting(true);

    if (formInitialValues.email !== values.email) {
      const emailIsExistingUser = Boolean(
        (await db.collection('users').where('email', '==', values.email).get()).docs.length,
      );

      if (emailIsExistingUser) {
        setErrors({ email: 'Email already in use for another user' });
        setIsSubmitting(false);
        return;
      }
    }

    // TODO: this is only happening when editing.. but couldn't you try to edit a contractor and set the email to one that already exists?
    // if (!isEditing) {
    //   const emailIsExistingContractor = Boolean(
    //     (await db.collection('contractors').where('email', '==', values.email).get()).docs.length,
    //   );

    //   if (emailIsExistingContractor) {
    //     setErrors({ email: 'Email already in use for another contractor' });
    //     setIsSubmitting(false);
    //     return;
    //   }
    // }

    const successCallback = () => {
      // todo: do we need this is submitting anymore?
      setIsSubmitting(false);
      //closeDialog();
    };

    dispatch(updateContractorUser(contractor.id, { ...values }, true, successCallback));
  };

  return (
    <div>
      <PageContent>
        <PageContentHeader className={cx('pb-0')}>
          <Typography variant="h5" className="page-content-title">
            My Info
          </Typography>
        </PageContentHeader>
        <PageContentBody>
          <Formik
            validationSchema={formSchema}
            initialValues={formInitialValues}
            onSubmit={handleFormSubmit}
            enableReinitialize={true} // Add this line
          >
            {({
              submitForm,
              resetForm,
              values,
              setFieldValue,
              errors,
              setErrors,
              submitCount,
              touched,
            }) => (
              <Form style={{ paddingBottom: 20 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      name="displayName"
                      type="text"
                      label="Name"
                      className="mb-4"
                      required
                    />

                    <TextInput
                      name="email"
                      type="email"
                      label="Primary Email"
                      className="mb-4"
                      required
                    />

                    <TextInput
                      name="vcEmail"
                      type="email"
                      label="VC Email"
                      className="mb-4"
                      readOnly={true}
                    />

                    <Grid container spacing={5}>
                      <Grid item xs={6}>
                        <InputLabel variant="standard">Secondary Emails</InputLabel>
                        <FieldArray
                          name="otherEmails"
                          render={arrayHelpers => (
                            <div>
                              {values.otherEmails.map((_, index) => (
                                <div key={index} className={classes.row}>
                                  <FieldText name={`otherEmails.${index}`} />
                                  <IconButton
                                    size="small"
                                    className={classes.removeButton}
                                    onClick={() => arrayHelpers.remove(index)} // remove an email from the list
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </div>
                              ))}

                              <Button
                                className={cx(classes.addButton, {
                                  'mt-1': !values.otherEmails.length,
                                })}
                                onClick={() => arrayHelpers.insert(values.otherEmails.length, '')}
                              >
                                Add
                                <AddCircleIcon />
                              </Button>
                            </div>
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <InputLabel variant="standard">Phone Numbers</InputLabel>

                        <FieldArray
                          name="phones"
                          render={arrayHelpers => (
                            <div>
                              {values.phones.map((_, index) => (
                                <div key={index} className={classes.row}>
                                  <FieldText name={`phones.${index}`} />
                                  <IconButton
                                    size="small"
                                    className={classes.removeButton}
                                    onClick={() => arrayHelpers.remove(index)} // remove an Phone from the list
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </div>
                              ))}

                              <Button
                                className={cx(classes.addButton, {
                                  'mt-1': !values.phones.length,
                                })}
                                onClick={() => arrayHelpers.insert(values.phones.length, '')} // insert an empty string at the end
                              >
                                Add
                                <AddCircleIcon />
                              </Button>
                            </div>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                      <Grid item xs={12}>
                        <InputLabel variant="standard">Languages</InputLabel>

                        <FieldArray
                          name="languages"
                          render={arrayHelpers => (
                            <div>
                              {values.languages.map((_, index) => (
                                <div key={index} className={classes.row}>
                                  <FieldText name={`languages.${index}`} />
                                  <IconButton
                                    size="small"
                                    className={classes.removeButton}
                                    onClick={() => arrayHelpers.remove(index)} // remove a language from the list
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </div>
                              ))}

                              <Button
                                className={cx(classes.addButton, {
                                  'mt-1': !values.languages.length,
                                })}
                                onClick={() => arrayHelpers.insert(values.languages.length, '')} // insert an empty string at the end
                              >
                                Add
                                <AddCircleIcon />
                              </Button>
                            </div>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider orientation="vertical" flexItem style={{ marginRight: '-1px' }} />

                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="address"
                          type="text"
                          label="Address"
                          className="mb-4 mr-sm-2"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="emergencyContactName"
                          type="text"
                          label="Emergency Contact Name"
                          className="mb-4 ml-sm-2"
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <div className="mr-sm-2 mb-4 mb-sm-0">
                          <AutoSuggestTextInput
                            name="city"
                            label="City"
                            required
                            options={map(cities, 'name')}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="emergencyContactPhone"
                          type="text"
                          label="Emergency Contact Phone"
                          className="mb-4 ml-sm-2"
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <div className="mr-sm-2 mb-4 mb-sm-0">
                          <AutoSuggestTextInput
                            name="state"
                            label="State"
                            required
                            options={map(states, 'name')}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="emergencyContactEmail"
                          type="email"
                          label="Emergency Contact Email"
                          className="mb-4 ml-sm-2"
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          name="postalCode"
                          type="text"
                          label="Postal Code"
                          required
                          className="mr-sm-2"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}></Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <div className="mr-sm-2 mb-4 mb-sm-0">
                          <AutoSuggestTextInput
                            name="country"
                            label="Country"
                            required
                            options={map(countries, 'name')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="ml-sm-2 mb-4 mb-sm-0">
                          <AutoSuggestTextInput
                            name="timeZone"
                            label="Time Zone"
                            options={TIME_ZONES}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className={classes.dialogActions}>
                  <Button
                    className={classes.cancelButton}
                    disabled={isSubmitting}
                    onClick={() => {
                      //closeDialog();
                      resetForm();
                    }}
                  >
                    CANCEL
                  </Button>
                  <div className={classes.saveButtonContainer}>
                    <Button
                      className={classes.saveButton}
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      SAVE
                      {isSubmitting && <ButtonSpinner />}
                    </Button>
                    {submitCount > 0 &&
                      Object.keys(errors).length > 0 &&
                      Object.keys(touched).length > 0 && (
                        <Typography color="error" className={classes.errorMessage}>
                          See errors above.
                        </Typography>
                      )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </PageContentBody>
      </PageContent>
    </div>
  );
};

// AddContractorForm.propTypes = {
//   closeDialog: PropTypes.func.isRequired,
// };

export default MyInfo;
