import React from 'react';
import cx from 'classnames';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';

import './dateTime.scss';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useLabelStyles = makeStyles({
  root: {
    marginBottom: 10,
  },
});

const useInputStyles = makeStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.04)',
  },
  input: {
    fontWeight: '400',
    paddingLeft: 5,
    paddingRight: 5,
  },
});

const MaterialDate = ({
  name,
  required,
  label,
  additionalStyles = {},
  storageFormat = 'YYYY-MM-DD',
  displayFormat = 'MM/DD/YYYY',
  disabled = false, // Add this line
}) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();
  const labelStyles = useLabelStyles();
  const inputStyles = useInputStyles();

  // const hasError = submitCount && error ? true : false;

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const handleChange = value => {
    if (value) {
      helpers.setValue(value.format(storageFormat));
    } else {
      helpers.setValue(null);
    }
  };

  const parseValue = value => {
    if (value) {
      return moment(value, storageFormat);
    } else {
      return null;
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={cx('datetime-picker', additionalStyles?.container)}>
        <InputLabel
          variant="standard"
          disableAnimation
          shrink={false}
          classes={labelStyles}
          error={hasError}
          required={required}
          htmlFor={field.name}
          className={additionalStyles?.label}
          disabled={disabled}
        >
          {label}
        </InputLabel>

        <KeyboardDatePicker
          disableToolbar
          value={parseValue(field.value)}
          onBlur={field.onBlur}
          name={field.name}
          variant="inline"
          format={displayFormat}
          margin="none"
          InputProps={{
            classes: inputStyles,
            readOnly: true,
            disabled: disabled,
          }}
          fullWidth
          autoOk
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          keyboardIcon={<EventIcon color="primary" />}
          className={additionalStyles.input}
          disabled={disabled}
        />

        {hasError ? (
          <Box component="span" color="error.main" fontWeight="400">
            {error}
          </Box>
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default MaterialDate;
