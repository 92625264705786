import { createSelector } from 'reselect';
import { isLoaded } from 'react-redux-firebase';
import {
  join,
  compact,
  map,
  flatMap,
  first,
  find,
  min,
  orderBy,
  uniqBy,
  includes,
  toLower,
  uniq,
  filter,
  size,
  get,
  isEmpty,
  some,
} from 'lodash';

import { checkProjectIsActive } from './getProject';
import { formatPrice } from 'utils/formatPrice';
import moment from 'moment';

const contractorsSelectors = state => state.firestore.data.contractors;
const projectsSelector = state => state.firestore.data.projects;
const currentContractorSelector = (state, id) => state.firestore.data.currentContractor;
const contractorSelector = (state, id) => state.firestore.data.contractors?.[id];
const projectMethodologiesSelector = state => state.firestore.data.projectMethodologies;
const dealsSelector = state => state.firestore.data.deals;
const companiesSelector = state => state.firestore.data.companies;
const staffBlockedTimesSelector = state => state.firestore.data.staffBlockedTimes;
const currentContractorBlockedTimesSelector = state =>
  state.firestore.data.currentContractorBlockedTimes;
const singleStaffBlockedTimeSelector = (state, id) =>
  state.firestore.data.currentContractorBlockedTimes?.[id];

const staffingSelector = state => state.staffing;

/**
 * Selector for retrieving all Tags
 */
export const selectAllContractorsTags = () => {
  return createSelector([contractorsSelectors], (contractors, staffing) => {
    if (!isLoaded(contractors)) return [];

    const tags = map(contractors, contractor => {
      return contractor?.tags ?? [];
    });

    return uniq(flatMap(compact(tags)));
  });
};

/**`
 * Selector for retrieving/filtering all Contractors
 */
export const selectContractors = () => {
  return createSelector(
    [
      contractorsSelectors,
      staffingSelector,
      projectsSelector,
      companiesSelector,
      dealsSelector,
      projectMethodologiesSelector,
      staffBlockedTimesSelector,
    ],
    (
      contractors,
      staffing,
      projects,
      companies,
      deals,
      projectMethodologies,
      staffBlockedTimes,
    ) => {
      if (!isLoaded(contractors)) return { isLoaded: false };

      const filteredContractors = map(contractors, contractor => {
        const contractorJobs = getContractorJobs(
          projects,
          contractor,
          companies,
          deals,
          projectMethodologies,
        );

        return filterOutContractors(contractor, staffing, contractorJobs, staffBlockedTimes);
      });
      return {
        contractors: orderBy(compact(filteredContractors), [
          item => (get(item, 'displayName') || '').trim().toLowerCase(),
        ]),
        isLoaded: true,
      };
    },
  );
};

/**
 * Since this selector is being used for multiple components in the
 * same view, it won't memoize correctly unless we create a new
 * copy of the selector each time it is called.
 */
export const selectContractor = () => {
  return createSelector(
    [
      contractorSelector,
      projectsSelector,
      companiesSelector,
      dealsSelector,
      projectMethodologiesSelector,
    ],
    (contractor, projects, companies, deals, projectMethodologies) => {
      if (!contractor) return null;

      const { phones, email } = contractor;

      const displayPhone = join(phones, ', ');
      const displayEmail = email;

      const contractorJobs = getContractorJobs(
        projects,
        contractor,
        companies,
        deals,
        projectMethodologies,
      );

      return {
        ...contractor,
        displayPhone,
        displayEmail,
        contractorJobs,
      };
    },
  );
};

/**
 * Selector for the Currently Signed-In Contractor
 * @returns
 */
export const selectSignedInContractor = () => {
  return createSelector(
    [
      currentContractorSelector,
      projectsSelector,
      companiesSelector,
      dealsSelector,
      projectMethodologiesSelector,
    ],
    (contractorData, projects, companies, deals, projectMethodologies) => {
      if (!contractorData || !projects) return null;

      //get the Contractor object from firestore contractors doc
      const contractor = first(Object.values(contractorData));
      if (!contractor) return null;

      //init the Contractor Jobs
      const contractorJobs = getContractorJobs(
        projects,
        contractor,
        companies,
        deals,
        projectMethodologies,
      );

      return {
        ...contractor,
        contractorJobs,
      };
    },
  );
};

/**
 * Get Contractor Jobs
 * @param {Object} projects
 * @param {Object} contractor
 * @param {Object} companies
 * @param {Object} deals
 * @param {Object} projectMethodologies
 *
 * @returns
 */
export const getContractorJobs = (
  projects,
  contractor,
  companies = {},
  deals = {},
  projectMethodologies = {},
) => {
  //init the Contractor Jobs
  const contractorJobs = [];

  if (!contractor) {
    return null;
  }

  //Get Jobs that the Contractor is assigned to...
  compact(
    map(orderBy(projects, ['startDate'], ['asc']), project => {
      if (!project) {
        return null;
      }
      if (!project.id) {
        return null;
      }

      map(project.jobs, job => {
        if (!job) {
          return null;
        }

        const assigneeInfo = find(job.assignees, ['contractorId', contractor.id]);
        if (assigneeInfo) {
          // the minimum rate between the Job hourly rate & Contractor Hourly Rate
          const minHourlyRate = min([Number(job.hourlyRate), Number(contractor.hourlyRate)]);

          const projectMethodology = projectMethodologies
            ? projectMethodologies[project.projectMethodologyId]
            : null;
          console.log('project', project.eventName);
          console.log('projectMethodologyId', project.projectMethodologyId);
          console.log('projectMethodology', projectMethodology);

          const projectDeal = deals ? deals[project.dealId] : null;
          const projectCompany = projectDeal ? companies[projectDeal.companyId] : null;
          const isPublic = projectCompany?.companyName === 'Voltage Control';

          contractorJobs.push({
            ...job,
            assigneeInfo,
            contractorId: contractor.id,
            contractorEmail: contractor.email,
            status: assigneeInfo.status ?? 'pending',
            displayHourlyRate: minHourlyRate,
            displayFee: job.isFixedFee
              ? `${formatPrice(job.fixedRate)}`
              : `${formatPrice(minHourlyRate)}/hr (max ${job.maxHours} hours)`,
            eventName: project.eventName,
            clientName: projectCompany?.companyName,
            isTentative: project.isTentative,
            projectStartDate: project.startDate,
            projectStartTime: project.startTime,
            projectEndDate: project.endDate,
            projectEndTime: project.endTime,
            projectEventRecurrenceType: project.calendarEventRecurrenceType,
            formattedProjectStartDate: project.startDate
              ? moment(project.startDate).format('MM/DD/YYYY')
              : 'Not Set',
            formattedProjectEndDate: project.endDate
              ? moment(project.endDate).format('MM/DD/YYYY')
              : 'Not Set',
            projectStatus: project.status,
            projectDescription: project.notes,
            projectMethodologyId: projectMethodology?.id || '',
            projectCompanyId: projectCompany?.id || '',
            projectMethodology: projectMethodology?.title || '',
            projectServiceType: project.serviceType || '',
            projectCompanyName: projectCompany?.companyName || '',
            projectId: project.id,
            projectIsPublic: isPublic,
            projectIsFree: project.isFree,
            projectVenue: project.venue,
            projectZoomLink: project.zoomLink,
            projectProjectBrief: project.projectBrief,
            projectChecklistDoc: project.checklistDoc,
            projectCalendarEvent: project.calendarEvent,
            projectProjectFolder: project.projectFolder,
            isActive: checkProjectIsActive(project) && assigneeInfo.status !== 'paid',
            isUpcoming: moment().isBefore(moment(project.endDate)),
          });
          //console.log('contractorJobs', contractorJobs);
        }
      });
    }),
  );

  return contractorJobs;
};

/**
 *
 * @param {*} staffing
 * @param {*} contractor
 * @param {*} contractorJobs
 * @returns
 */
export const filterOutContractors = (
  contractor,
  staffing,
  contractorJobs = [],
  blockedTimes = [],
) => {
  //Check if Filter is set,
  //And if set, and the property don't match with the Filter, skip the Contractor

  const {
    roleFilter,
    secondaryRoleFilter,
    seniorityFilter,
    maxRateFilter,
    cityFilter,
    stateFilter,
    countryFilter,
    timeZoneFilter,
    specialtyFilter,
    tagsFilter,
    languagesFilter,
    staffingTypeFilter,
    companiesWorkedWithFilter,
    methodologiesWorkedWithFilter,
    availableOnFilter,
  } = staffing;

  const acceptedContractorJobs = filter(contractorJobs, m =>
    includes(['accepted', 'signed', 'paid'], m.status),
  );

  if (roleFilter && contractor.type !== roleFilter) {
    return null;
  }

  if (secondaryRoleFilter && contractor.secondaryRoleType !== secondaryRoleFilter) {
    return null;
  }

  if (staffingTypeFilter && contractor.staffType !== staffingTypeFilter) {
    return null;
  }

  if (seniorityFilter && contractor.seniority !== seniorityFilter) {
    return null;
  }

  if (availableOnFilter) {
    const availableOnDate = moment(availableOnFilter, 'YYYY-MM-DD');
    const alreadyWorkingJob =
      !isEmpty(contractorJobs) &&
      some(contractorJobs, job => {
        const jobStartDate = moment(job.projectStartDate, 'YYYY-MM-DD');
        const jobEndDate = moment(job.projectEndDate || job.projectStartDate, 'YYYY-MM-DD');
        return availableOnDate.isBetween(jobStartDate, jobEndDate, null, '[]');
      });

    // Check blocked times using Lodash
    const isBlocked =
      !isEmpty(blockedTimes) &&
      some(blockedTimes, blockedTime => {
        const blockedTimeStart = moment(get(blockedTime, 'startDate'), 'YYYY-MM-DD');
        const blockedTimeEnd = moment(get(blockedTime, 'endDate'), 'YYYY-MM-DD');
        return availableOnDate.isBetween(blockedTimeStart, blockedTimeEnd, null, '[]');
      });

    if (alreadyWorkingJob || isBlocked) {
      return null;
    }
  }

  if (maxRateFilter && Number(contractor.hourlyRate) > Number(maxRateFilter)) {
    return null;
  }

  if (cityFilter && contractor.city !== cityFilter) {
    return null;
  }

  if (stateFilter && contractor.state !== stateFilter) {
    return null;
  }

  if (countryFilter && contractor.country !== countryFilter) {
    return null;
  }

  if (timeZoneFilter && contractor.timeZone !== timeZoneFilter) {
    return null;
  }

  if (specialtyFilter && !includes(toLower(contractor.notes), toLower(specialtyFilter))) {
    return null;
  }

  if (tagsFilter && !includes(contractor.tags ?? [], tagsFilter)) {
    return null;
  }

  if (languagesFilter && !includes(contractor.languages ?? [], languagesFilter)) {
    return null;
  }

  if (
    companiesWorkedWithFilter &&
    !includes(map(acceptedContractorJobs, 'projectCompanyId'), companiesWorkedWithFilter)
  ) {
    return null;
  }

  console.log('methodologiesWorkedWithFilter', methodologiesWorkedWithFilter);
  if (
    methodologiesWorkedWithFilter &&
    !includes(map(acceptedContractorJobs, 'projectMethodologyId'), methodologiesWorkedWithFilter)
  ) {
    if (acceptedContractorJobs.length > 0) {
      console.log('methodologiesWorkedWithFilter', methodologiesWorkedWithFilter);
      console.log('mappedJobs', map(acceptedContractorJobs, 'projectMethodologyId'));
      console.log(
        'includes',
        includes(
          map(acceptedContractorJobs, 'projectMethodologyId'),
          methodologiesWorkedWithFilter,
        ),
      );
    }
    return null;
  }

  return contractor;
};

export const selectFiltersSelectOptions = () => {
  return createSelector([contractorsSelectors], contractors => {
    if (!isLoaded(contractors))
      return {
        cities: [],
        states: [],
        countries: [],
        timezones: [],
        languages: [],
        tags: [],
      };

    //Get All Unique Cities from Contractor
    const cities = orderBy(
      uniqBy(
        map(contractors, contractor => {
          return contractor?.city ?? '';
        }),
      ).map(value => {
        return {
          name: value,
          value,
        };
      }),
      [item => get(item, 'name').toLowerCase()],
    );

    //Get All Unique States from Contractor
    const states = orderBy(
      compact(
        uniqBy(
          map(contractors, contractor => contractor?.state ?? ''),
          state => state.toLowerCase(),
        ),
      )
        .filter(state => !!state)
        .map(value => ({
          name: value,
          value,
        })),
      [item => item.name.toLowerCase()],
    );

    const countries = orderBy(
      uniqBy(
        map(contractors, contractor => {
          return contractor?.country ?? '';
        }),
      ).map(value => {
        return {
          name: value,
          value,
        };
      }),
      [item => get(item, 'name').toLowerCase()],
    );

    const timezones = orderBy(
      uniqBy(
        compact(
          map(contractors, contractor => {
            return contractor?.timeZone ?? '';
          }),
        ),
      ).map(tz => {
        return {
          name: tz,
          value: tz,
        };
      }),
      [item => get(item, 'name').toLowerCase()],
    );

    //Get All Unique States from Contractor
    const languages = orderBy(
      compact(
        uniqBy(
          flatMap(contractors, contractor => contractor?.languages ?? []),
          language => language.toLowerCase(),
        ),
      )
        .filter(language => !!language)
        .map(value => ({
          name: value,
          value,
        })),
      [item => item.name.toLowerCase()],
    );

    const allTags = uniq(
      flatMap(
        compact(
          map(contractors, contractor => {
            return contractor?.tags ?? [];
          }),
        ),
      ),
    );

    return {
      cities,
      states,
      countries,
      languages,
      timezones,
      tags: orderBy(
        map(allTags, tag => {
          return { name: tag, value: tag };
        }),
        [item => get(item, 'name').toLowerCase()],
      ),
    };
  });
};

/**
 * Selector for retrieving a single Staff Blocked Time
 * @returns
 */
export const selectSingleStaffBlockedTime = () => {
  return createSelector([singleStaffBlockedTimeSelector], blockedTime => {
    if (!blockedTime) return null;
    return blockedTime;
  });
};

/**
 * Selector for retrieving all Staff Blocked Times
 * @returns
 */
export const selectCurrentContractorBlockedTimes = () => {
  return createSelector([currentContractorBlockedTimesSelector], blockedTimes => {
    console.log('blockedTimes SELECTOR:', blockedTimes);
    console.log('isLoaded:', isLoaded(blockedTimes));
    if (!isLoaded(blockedTimes)) return { isLoaded: false };

    if (isEmpty(blockedTimes)) return { blockedTimes: [], isLoaded: true };

    // Convert the blockedTimes object to an array
    const blockedTimesArray = Object.values(blockedTimes);
    console.log('blockedTimesArray:', blockedTimesArray);
    return {
      blockedTimes: orderBy(
        compact(blockedTimesArray),
        [item => moment(`${item.startDate}T${item.startTime}`).valueOf()],
        ['desc'], // Add this argument to sort in descending order
      ),
      isLoaded: true,
    };
  });
};
